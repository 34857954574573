.vitamui-input {
  mat-spinner {
    position: absolute;
    top: 12px;
    right: 12px;
    display: none;
  }
}

mat-radio-button {
  display: flex;
  flex-direction: row;
  margin-top: 15px;

  div,
  .mdc-form-field {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    label {
      display: flex;
      cursor: pointer;
      align-items: center;
    }
  }
}
