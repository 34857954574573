$anim-timing: 150ms ease-out;

.mat-drawer,
.mat-dialog-container {
  .vitamui-input {
    @include dialog-elevation-dark;
  }
}

.vitamui-input {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: none;
  background-color: white;
  padding: 10px 15px;
  transition: border-color $anim-timing;
  vertical-align: top;

  &:not(.editable-field) {
    height: 60px;
  }

  &.disabled {
    border: none !important;
    label {
      color: var(--vitamui-grey-700) !important;
    }

    mat-datepicker-toggle mat-icon {
      color: var(--vitamui-grey) !important;
    }
  }

  @include input-elevation-dark;

  label {
    position: absolute;
    font-size: 14px;
    font-weight: 700;
    color: var(--vitamui-primary) !important;
    letter-spacing: 0.1px;
    line-height: 10px;
    white-space: nowrap;
    transform-origin: left;
    transition: transform $anim-timing;

    .required-marker {
      color: $red;
    }
  }

  input,
  textarea {
    font-size: 16px;
    font-weight: 400;
    color: var(--vitamui-grey-900);
    background: none;
    border: none;
    outline: none;
    line-height: 24px;
  }

  input {
    line-height: 18px;
    transform-origin: left;
    transition: margin $anim-timing;
    width: 100%;
  }

  &.vitamui-date {
    .mat-form-field-underline {
      display: none;
    }
    .mat-form-field-label {
      .mat-placeholder-required {
        color: $red;
      }
    }
    .mat-icon-button {
      font-size: 25px !important;
      .mat-icon {
        font-size: 25px !important;
      }
    }

    .mat-form-field-label span {
      line-height: 15px;
    }

    .mat-form-field-flex {
      background-color: unset;
    }

    &.mat-form-field-disabled {
      background-color: var(--vitamui-grey-300);
      border: none !important;
      @include elevation-0;
    }

    .mat-input-element {
      color: unset;
    }
  }

  textarea {
    resize: none;
    width: 100%;
    height: 80%;
  }

  &.disabled {
    background-color: var(--vitamui-grey-200);
    border: none;
  }

  &.disabled > * {
    color: var(--vitamui-grey-700);

    input {
      color: var(--vitamui-grey-900);
    }
  }
}

.vitamui-focused .vitamui-input {
  border: solid 1px var(--vitamui-primary);
}

.vitamui-input:focus-within,
.focused .vitamui-input {
  outline: solid 1px var(--vitamui-primary);
}

.vitamui-focused .vitamui-input,
.vitamui-input:focus-within,
.vitamui-float .vitamui-input {
  label:first-of-type {
    transform: scale((12 / 14)) translateY(-12px);
    letter-spacing: 0.4px;
    line-height: 10px;
  }

  input {
    margin-top: 15px;
    padding: 0px;
  }
  textarea {
    margin-top: 25px;
    padding: 0px;
  }
}
