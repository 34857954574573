.vitamui-chip-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .vitamui-chip {
    display: flex;
    align-items: stretch;
    font-size: 13px;
    cursor: pointer;
    min-width: 140px;

    &:not(:last-child) {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .vitamui-chip-content {
      padding: 6px 10px;
      flex-grow: 1;
    }

    .vitamui-remove-chip {
      display: flex;
      align-items: center;
      color: var(--vitamui-primary-300);
      border-left: 1px solid var(--vitamui-primary-300);
      padding: 6px;

      i {
        font-size: 14px;
      }
    }
  }
}

.mat-drawer,
.mat-dialog-container {
  .vitamui-chip {
    @include dialog-elevation-primary;
  }
}
.vitamui-chip {
  background-color: white;
  border: 1px solid var(--vitamui-primary-300);
  border-radius: 5px;
  color: var(--vitamui-grey-900);
  font-size: 12px;
  font-weight: bold;
  @include btn-elevation-primary;

  &:disabled {
    border: 1px solid var(--vitamui-grey);
  }

  &.activated {
    color: white !important;
    background-color: #2c76c9 !important;
  }
}
