.vitamui-spinner {
  &.mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: var(--vitamui-grey-600);
  }

  &.primary circle {
    stroke: var(--vitamui-primary);
  }

  &.secondary circle {
    stroke: var(--vitamui-secondary);
  }

  &.mat-progress-spinner svg {
    width: 50px;
    height: 50px;
  }

  &.x-large {
    width: 200px !important;
    height: 200px !important;

    &.mat-progress-spinner svg {
      width: 200px !important;
      height: 200px !important;
    }

    &.mat-progress-spinner circle,
    .mat-spinner circle {
      stroke-width: 5% !important;
    }
  }

  &.large {
    width: 100px !important;
    height: 100px !important;

    &.mat-progress-spinner svg {
      width: 100px !important;
      height: 100px !important;
    }

    &.mat-progress-spinner circle,
    .mat-spinner circle {
      stroke-width: 7% !important;
    }
  }

  &.medium {
    width: 50px !important;
    height: 50px !important;

    &.mat-progress-spinner svg {
      width: 50px !important;
      height: 50px !important;
    }
  }

  &.small {
    width: 25px !important;
    height: 25px !important;
    &.mat-progress-spinner svg {
      width: 25px !important;
      height: 25px !important;
    }
  }
}
