@import './variables/colors';

.status-badge-green {
  @include status-badge;

  &:after {
    background-color: $green;
    border: solid 2px $white;
  }

  &.status-badge-mini {
    @include status-badge-mini;
  }
}

.status-badge-red {
  @include status-badge;

  &:after {
    background-color: $red;
    border: solid 2px $white;
  }

  &.status-badge-mini {
    @include status-badge-mini;
  }
}

.status-badge-orange {
  @include status-badge;

  &:after {
    background-color: $mustard-yellow;
    border: solid 2px $white;
  }

  &.status-badge-mini {
    @include status-badge-mini;
  }
}

.status-badge-grey {
  @include status-badge;

  &:after {
    background-color: $light-grey;
    border: solid 2px $white;
  }

  &.status-badge-mini {
    @include status-badge-mini;
  }
}

.status-badge-dark-grey {
  @include status-badge;

  &:after {
    background-color: #dadada;
    border: solid 2px $white;
  }

  &.status-badge-mini {
    @include status-badge-mini;
  }
}

.status-badge-black {
  @include status-badge;

  &:after {
    background-color: $black;
    border: solid 2px $white;
  }

  &.status-badge-mini {
    @include status-badge-mini;
  }
}

.status-badge-icon-cross {
  &:after {
    border: none;
    font-family: 'vitamui-icon';
    content: '\ea0f';
    font-size: 8px;
    line-height: 8px;
    color: $white;
  }
}
