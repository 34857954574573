.vitamui-tab-group.mat-tab-group {
  height: 100%;

  .mat-tab-header {
    border-bottom: none;
    height: 56px;
  }

  .mat-tab-body {
    height: 100%;
  }

  .mat-tab-label {
    background-color: white;
    color: var(--vitamui-grey-600);
    min-width: 130px;
    opacity: 1;
    height: 56px;
    font-size: 16px;
    &:hover {
      background-color: var(--vitamui-grey-300);
    }

    &:not(.mat-tab-disabled):focus {
      background-color: var(--vitamui-grey-300);
    }
  }

  .mat-tab-label-active {
    .mat-tab-label-content {
      color: var(--vitamui-primary);
      font-weight: 700;
    }
  }

  .mat-tab-body-content {
    margin-top: 20px;
    height: calc(100% - 40px);
    overflow: hidden;
  }

  .mat-ink-bar {
    background-color: transparent !important;
    height: 3px;

    &::before {
      content: '';
      display: block;
      width: 40%;
      background-color: var(--vitamui-primary);
      height: 6px;
      border-bottom-right-radius: 70px;
      border-bottom-left-radius: 70px;
      left: 30%;
      position: relative;
    }
  }
}
