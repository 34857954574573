@mixin status-badge {
  position: relative;

  &:after {
    @include elevation-2-dark;
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 100%;
    top: 100%;
    margin-left: -8px;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    overflow: hidden;
    font-size: 16px;
    line-height: 16px;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
  }
}

@mixin status-badge-mini {
  &:after {
    margin-left: -6px;
    margin-top: -6px;
    width: 8px;
    height: 8px;
    border: solid 1px #ffffff;
  }
}
