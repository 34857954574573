@import './variables/colors';

.read-only-field {
  display: inline-block;
  min-height: 60px;
  width: 100%;
  padding: 5px;
  vertical-align: top;
  margin-bottom: 10px;
  font-weight: 400;

  label {
    font-size: 12px;
    font-weight: 700;
    color: var(--vitamui-primary);
  }

  div {
    color: var(--vitamui-grey-900);
  }
}

.read-only-field-design {
  display: inline-block;
  min-height: 60px;
  width: 100%;
  padding: 5px;
  vertical-align: top;
  margin-bottom: 10px;
  font-weight: 400;
  background-color: var(--vitamui-grey-300);
  border-radius: 4px;
  align-items: center;
  position: relative;
  border: none;
  transition: border-color 150ms ease-out;

  label {
    font-size: 14px;
    font-weight: 700;
    color: var(--vitamui-primary);
    line-height: 50px;
    margin-left: 25px;
  }

  div {
    color: var(--vitamui-grey-900);
    font-weight: 700;
  }
}

.vitamui-input-errors {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: $red;
  display: flex;
  align-items: center;
  padding: 5px 0 0 0;

  i {
    font-size: 12px;
    margin-right: 5px;
  }
}

input[type='text'].vitamui-input,
input[type='password'].vitamui-input,
input[type='email'].vitamui-input {
  outline: none;
  padding: 0 30px;
  width: 100%;
  max-width: 300px;
  border: none;
  border-radius: 4px;
  font-size: 16px;

  &.ng-valid.ng-touched {
    border: 1px solid $green;
  }

  &.ng-invalid.ng-touched {
    border: 1px solid $red;
  }

  &.vitamui-small {
    height: 34px;
    border-radius: 4px;
  }

  &::-webkit-input-placeholder {
    color: var(--vitamui-grey-600);
    font-weight: 400;
  }

  &::-moz-placeholder {
    color: var(--vitamui-grey-600);
    font-weight: 400;
  }

  &:-ms-input-placeholder {
    color: var(--vitamui-grey-600);
    font-weight: 400;
  }

  &:-moz-placeholder {
    color: var(--vitamui-grey-600);
    font-weight: 400;
  }
}
