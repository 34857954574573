.vitamui-icon-app_unknown .path1:before {
  content: '\ea4d';
  color: rgb(226, 226, 225);
}
.vitamui-icon-app_unknown .path2:before {
  content: '\ea56';
  margin-left: -1.3505859375em;
  color: rgb(154, 154, 154);
}
.vitamui-icon-app_unknown .path3:before {
  content: '\ea57';
  margin-left: -1.3505859375em;
  color: rgb(197, 197, 197);
}
.vitamui-icon-app_unknown .path4:before {
  content: '\ea58';
  margin-left: -1.3505859375em;
  color: rgb(239, 239, 239);
}
.vitamui-icon-app_unknown .path5:before {
  content: '\ea5a';
  margin-left: -1.3505859375em;
  color: rgb(197, 197, 197);
}
.vitamui-icon-app_unknown .path6:before {
  content: '\ea5b';
  margin-left: -1.3505859375em;
  color: rgb(154, 154, 154);
}
.vitamui-icon-app_unknown .path7:before {
  content: '\ea5c';
  margin-left: -1.3505859375em;
  color: rgb(197, 197, 197);
}
.vitamui-icon-app_unknown .path8:before {
  content: '\ea5d';
  margin-left: -1.3505859375em;
  color: rgb(197, 197, 197);
}
.vitamui-icon-app_unknown .path9:before {
  content: '\ea5e';
  margin-left: -1.3505859375em;
  color: rgb(197, 197, 197);
}
.vitamui-icon-app_unknown .path10:before {
  content: '\ea5f';
  margin-left: -1.3505859375em;
  color: rgb(239, 239, 239);
}
.vitamui-icon-app_unknown .path11:before {
  content: '\ea60';
  margin-left: -1.3505859375em;
  color: rgb(197, 197, 197);
}
.vitamui-icon-app_unknown .path12:before {
  content: '\ea61';
  margin-left: -1.3505859375em;
  color: rgb(154, 154, 154);
}
.vitamui-icon-app_unknown .path13:before {
  content: '\ea62';
  margin-left: -1.3505859375em;
  color: rgb(133, 133, 133);
}
.vitamui-icon-app_unknown .path14:before {
  content: '\ea63';
  margin-left: -1.3505859375em;
  color: rgb(133, 133, 133);
}
.vitamui-icon-app_unknown .path15:before {
  content: '\ea64';
  margin-left: -1.3505859375em;
  color: var(--vitamui-primary-400);
}
.vitamui-icon-app_unknown .path16:before {
  content: '\ea65';
  margin-left: -1.3505859375em;
  color: var(--vitamui-primary-400);
}
.vitamui-icon-app_unknown .path17:before {
  content: '\ea66';
  margin-left: -1.3505859375em;
  color: var(--vitamui-primary-400);
}
.vitamui-icon-app_unknown .path18:before {
  content: '\ea67';
  margin-left: -1.3505859375em;
  color: var(--vitamui-primary-400);
}
.vitamui-icon-app_unknown .path19:before {
  content: '\ea68';
  margin-left: -1.3505859375em;
  color: #fff;
}
.vitamui-icon-app_unknown .path20:before {
  content: '\ea69';
  margin-left: -1.3505859375em;
  color: var(--vitamui-primary-300);
}
.vitamui-icon-app_unknown .path21:before {
  content: '\ea6a';
  margin-left: -1.3505859375em;
  color: var(--vitamui-primary-200);
}
.vitamui-icon-app_unknown .path22:before {
  content: '\ea6b';
  margin-left: -1.3505859375em;
  color: var(--vitamui-primary-400);
}
.vitamui-icon-app_unknown .path23:before {
  content: '\ea6c';
  margin-left: -1.3505859375em;
  color: var(--vitamui-primary-300);
}
.vitamui-icon-app_unknown .path24:before {
  content: '\ea6d';
  margin-left: -1.3505859375em;
  color: var(--vitamui-primary-400);
}
.vitamui-icon-app_unknown .path25:before {
  content: '\ea6f';
  margin-left: -1.3505859375em;
  color: #fff;
}
.vitamui-icon-app_unknown .path26:before {
  content: '\ea70';
  margin-left: -1.3505859375em;
  color: #fff;
}
.vitamui-icon-app_unknown .path27:before {
  content: '\ea71';
  margin-left: -1.3505859375em;
  color: #fff;
}
