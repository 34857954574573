.image-area {
  border: 2px dashed var(--vitamui-grey-600);
  border-radius: 6px;

  height: 100px;
  max-width: 200px;

  img {
    max-width: 180px;
  }
}
