.mat-progress-bar {
  height: 8px !important;

  .mat-progress-bar-buffer {
    background-color: var(--vitamui-primary-50);
  }

  .mat-progress-bar-fill::after {
    background-color: var(--vitamui-primary);
  }
  border-radius: 100px;
}
