@import '../mixins/elevation';

.vitamui-autocomplete-panel {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  background-color: $white;
  border: solid 1px var(--vitamui-grey-400);

  .mat-option {
    height: 50px;
    padding: 14px 16px;
  }

  .mat-option:not(:last-child) {
    border-bottom: 1px solid var(--vitamui-grey-300);
  }

  // FIXME: Seems that var( --colorName ) cant be applied in transparentize function. How to load specific color with a composite here ?
  .mat-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled),
  .mat-option.mat-active {
    background: var(--vitamui-grey-300);
  }

  .mat-option-text {
    display: flex;
    align-items: center;

    font-size: 14px;
    line-height: 22px;
    color: var(--vitamui-grey-900);
    letter-spacing: 0.1px;

    > i {
      color: var(--vitamui-primary);
      margin-left: 10px;
    }
  }
}

.mat-autocomplete-panel.mat-autocomplete-visible {
  @include elevation-0;
  width: 90%;
  left: 5%;
}
