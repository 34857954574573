.mat-drawer,
.mat-dialog-container {
  .search {
    input[type='search'],
    input[type='text'] {
      @include dialog-elevation-dark;
    }
  }
}

.search {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;
  max-width: 700px;
  flex-grow: 1;

  &.disabled {
    opacity: 0.3;
  }

  input[type='search'],
  input[type='text'] {
    border: none;
    width: 100%;
    height: 50px;
    border-radius: 50px * 0.5;
    background-color: $white;
    @include input-elevation-dark;
    border: none;
    padding: 10px 90px 10px 28.5px;
    display: flex;
    outline: none;

    &::placeholder {
      @include vitamui-input-placeholder;
      font-weight: normal;
    }

    &:disabled {
      background-color: var(--vitamui-grey-200);
      cursor: not-allowed;
    }
  }

  > button[type='submit'].btn-search,
  > button[type='button'].btn-search {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  > button.btn-reset {
    position: absolute;
    top: 10px;
    right: 50px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    color: $charcoal-grey;
    border: none;
    cursor: pointer;

    > i {
      font-size: 18px;
    }
  }
}
