@import './variables/colors';

$anim-timing: 150ms ease-out;

.editable-field-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;

  .vitamui-input-errors {
    padding: 4px 25px;
    overflow: hidden;
  }
}

.editable-field {
  justify-content: space-between;
  min-height: 60px;
  width: 100% !important;
  cursor: pointer;
  outline: none;

  &.squarish {
    border-radius: 10px;
  }

  i.edit-icon {
    color: var(--vitamui-primary);
    opacity: 0;
    transition: opacity $anim-timing;
    position: absolute;
    right: 20px;
    pointer-events: none;
  }

  &:hover:not(.edit-mode),
  &:focus:not(.edit-mode) {
    i.edit-icon {
      opacity: 1;
    }
  }
}

.editable-field-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  position: relative;
  max-width: 100%;

  .editable-field-text-content {
    margin-top: 15px;
    width: 100%;
    font-weight: 700;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .editable-field-control {
    display: none;
  }

  .editable-field-control > input {
    margin-top: 5px;
    padding: 0px;

    &:focus {
      outline: none;
    }
  }

  .editable-field-control > textarea {
    border: none !important;

    &:focus {
      outline: none;
    }
  }
}

.editable-field.edit-mode {
  border: solid 1px var(--vitamui-primary);
  padding: 10px 15px;

  .editable-field-content {
    > label:first-of-type {
      transform: scale((12 / 14)) translateY(-15px);
      letter-spacing: 0.4px;
      line-height: 16px;
    }

    .editable-field-text-content {
      display: none;
    }

    .editable-field-control {
      display: flex;
    }
  }
}

.editable-field-actions {
  width: 85px;
  height: 50px;
  border-radius: 25px;
  padding: 10px;
  background-color: white;
  @include elevation-1-dark;
  z-index: 1;
  margin: 10px 0 10px 10px;
  display: flex;
  justify-content: space-between;

  button {
    @include elevation-1-dark;
    background: none;
    border: none;
    color: white;
    height: 30px;
    width: 30px;
    padding: 0;
    border-radius: 20px;
    transition:
      opacity 200ms ease-out,
      background-color 200ms ease-out;

    &:disabled {
      opacity: 0.4;
    }

    > i {
      font-size: 18px;
    }

    &.editable-field-confirm {
      background-color: $green;

      &:hover,
      &:active,
      &:focus {
        background-color: lighten($green, 15%);
      }
    }

    &.editable-field-cancel {
      background-color: $red;

      &:hover,
      &:active,
      &:focus {
        background-color: lighten($red, 15%);
      }
    }
  }
}

.editable-field-square .editable-field {
  border-radius: 10px;
}

.squarish {
  border-radius: 10px !important;
}
