.mat-drawer {
  border-left: none !important;
  @include elevation-5-dark;
}

.preview-tab-group:not(.show-pagination).mat-tab-group {
  .mat-tab-header {
    .mat-tab-header-pagination {
      display: none;
    }
  }
}

.preview-tab-group.show-pagination.mat-tab-group {
  .mat-tab-header {
    .mat-tab-header-pagination-disabled {
      display: none;
    }
  }
}
.preview-tab-group.mat-tab-group {
  height: 100%;
  .mat-tab-header {
    border-bottom: none;
    background-color: white;
    height: 40px;
    position: fixed;
    width: 584px;
    z-index: 10;
  }

  .mat-tab-label {
    color: var(--vitamui-grey-600);
    align-items: baseline;
    min-width: 130px;
    opacity: 1;
    height: 40px;
    padding-top: 3px;

    &:hover {
      background-color: var(--vitamui-grey-300);
    }

    &:not(.mat-tab-disabled):focus {
      background-color: var(--vitamui-grey-300);
    }
  }

  .mat-tab-label-active {
    .mat-tab-label-content {
      color: var(--vitamui-primary);
      font-size: 16px;
      font-weight: 400;
    }
  }

  .mat-tab-labels {
    padding: 0 30px;
  }

  .mat-tab-body-content {
    margin-top: 40px;
    padding: 20px 40px;
    height: calc(100% - 40px);
  }

  .mat-ink-bar {
    background-color: transparent !important;
    height: 3px;

    &::before {
      content: '';
      display: block;
      width: 69%;
      background-color: var(--vitamui-primary);
      height: 6px;
      border-bottom-right-radius: 70px;
      border-bottom-left-radius: 70px;
      left: 16%;
      position: relative;
    }
  }
}
